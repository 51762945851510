import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { device } from './variables';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    children: null,
};

const FormWrap = styled.div`
    width: 95%;
    max-width: 865px;
    margin: auto;
    
    @media ${device.mobile} {
        width: 95%;
    }
    @media ${device.pad} {
        width: 95%;
    }
`;

const FormWrapper = (props) => {
    const { children } = props;
    return (
        <FormWrap>
            { children }
        </FormWrap>
    )
}

FormWrap.propTypes = propTypes;
FormWrap.defaultProps = defaultProps;

export default FormWrapper;
