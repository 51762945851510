import React, {Fragment} from "react";
import styled from "styled-components"
import HeadH1 from "../Heading";
import HtmlLink from "../Link";
import Image from "../Image";
import { device, titles } from '../variables';
import $ from 'jquery';
import { variables } from '../variables'

const Card = styled.div`
    display: flex;
    justify-content: space-around;
    height: 600px;
    flex-direction: row;
    align-items: flex-end;

  @media ${device.mobile} {
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: auto;
 }
 @media ${device.pad} {
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    height: 450px;
    margin: auto;
 }
`;

const LinkWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: -12px;
    margin: 0 200px;
    
    @media ${device.pad} {
    justify-content: center;
    top: 0;
    margin: 0 0 15px;
    }
    
    @media ${device.mobile} {
    justify-content: center;
    top: 0;
    margin: 0 0 15px;
    }
`;

$(function() {
    // if(window.location.href === variables.baseUrl + '#/') {
    //     $('a[href="#/postform"] > .button_next').attr('disabled', 'disabled');
    // }
    $('.convert').on('click', function() {
        let mainSource = $(this).attr('src');
        let needSource = '';
        needSource = mainSource.replace('jpg', 'gif');
        // $('a[href="#/postform"] > .button_next').removeAttr('disabled');

        if($('.convert').hasClass('active')) {
            $('.convert').removeClass('active');
            $(this).attr('src', needSource);
            $(this).addClass('opened');
            $(this).addClass('active');
            $('.convert').css('opacity', '0.5');
            $(this).css('opacity', '1');
        } else {
            $(this).attr('src', needSource);
            $(this).addClass('opened');
            $(this).addClass('active');
            $('.convert').css('opacity', '0.5');
            $(this).css('opacity', '1');
        }
    });
});

class IndexPage extends React.Component {

    render() {
        return (
            <Fragment>
                <HeadH1 text={titles.main} />
                <LinkWrapper>
                    <HtmlLink link="/postform" text="Далее" />
                </LinkWrapper>
                <Card>
                    <Image src="./img/counverts/1.jpg" />
                    <Image src="./img/counverts/2.jpg" />
                    <Image src="./img/counverts/3.jpg" />
                </Card>
            </Fragment>
        )
    }
}

export default IndexPage
