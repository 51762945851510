import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    children: null,
};

const Wrapper = styled.main`
    margin: 0;
    min-height: 100vh;
    font-family: "-apple-system, Roboto, sans-serif, serif";
    padding: 0 0 15px;
    box-sizing: border-box;
`;

const MainBlock = (props) => {
    const { children } = props;
    return (
        <Wrapper>
            { children }
        </Wrapper>
    )
}

MainBlock.propTypes = propTypes;
MainBlock.defaultProps = defaultProps;

export default MainBlock
