import React, {Fragment} from 'react';
import styled from 'styled-components';
import HeadH1 from "../Heading";
import {titles, device, variables} from '../variables';
import YandexShare from 'react-yandex-share';
import axios from 'axios';


const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    
    @media ${device.pad} {
    flex-direction: column;
    align-items: center;
    }
    
    @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    }
`;


class Send extends React.Component {
    state = {
        postcard: localStorage.getItem('src'),
        preview: localStorage.getItem('preview'),
    }

    render() {
        return (
            <Fragment>
                <HeadH1 text={titles.cardsend} />
                <CardWrapper>
                    <img className="card" src={this.state.postcard} />
                    <img className="card" src={this.state.preview} />
                </CardWrapper>
                <div align="center">
                    <p className="share_heading">Поделитесь поздравлением в социальных сетях или мессенджере!</p>
                    <YandexShare
                        content={{
                            url: 'https://lukoilpostcard.ru/',
                            title: 'Онлайн открытка',
                            description: 'Создайте открытку с уникальным дизайном и поздравьте с Новым Годом своих друзей и близких!',
                            image: localStorage.getItem('imageURL')
                        }}
                        theme={{
                            lang: 'ru',
                            size: 'l',
                            services: 'vkontakte,facebook,twitter,odnoklassniki,telegram,viber,whatsapp'
                        }}
                    />
                </div>
                <div align="center" className="another_one">
                    <a href={variables.baseUrl} className="button_next">Отправить еще одну открытку</a>
                </div>
            </Fragment>
        )
    }
}

export default Send;
