import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { device, variables } from './variables';

const propTypes = {
    text: PropTypes.string.isRequired,
};

const Heading = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    font-family: ${variables.font};
    font-weight: 300;
    font-size: ${variables.fontSizes.desktop};
    
    @media ${device.pad} {
    margin-bottom: 10px;
    font-size: ${variables.fontSizes.pad};
    line-height: 30px;
    }

    @media ${device.mobile} {
    font-size: ${variables.fontSizes.mobile};
    margin-bottom: 10px;
    line-height: 24px;
    }
`;

const HeadH1 = (props) => {
    const { text } = props;
    return (
        <Heading>
            { text }
        </Heading>
    )
}

HeadH1.propTypes = propTypes;

export default HeadH1;
