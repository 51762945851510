import * as React from "react"

const headerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '100%',
    textAlign: 'center',
    fontSize: '1.3em',
    backgroundColor: '#fff',
}

const Header = () => {
    return (
        <div style={headerStyles}>Открытка от Лукойла</div>
    )
}

export default Header
