import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

const defaultProps = {
    text: "Далее",
};

class HtmlLink extends React.Component {
    state = {
        disabled: this.props.disabled,
    }

    setDisabled = () => {
        if (this.props.disabled === false) {
            this.setState({
                disabled: false,
            })
        }
    }

    render() {
        const { link, text } = this.props;
        const { disabled } = this.state;
        return (
            <Link to={ link }>
                <button className="button_next" type="button">
                    { text }
                </button>
            </Link>
        )
    }
}

HtmlLink.propTypes = propTypes;
HtmlLink.defaultProps = defaultProps;

export default HtmlLink;
