import { variables } from '../components/variables'

export default  [
    {
        "id": 1,
        "url": variables.baseUrl + "#/",
    },
    {
        "id": 2,
        "url": variables.baseUrl + "#/postform",
    },
    {
        "id": 3,
        "url": variables.baseUrl + "#/addstickers",
    },
    {
        "id": 4,
        "url": variables.baseUrl + "#/sendform",
    },
    {
        "id": 5,
        "url": variables.baseUrl + "#/sended",
    }
];
