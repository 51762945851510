import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import data from "./../data/circleData"
import { variables } from './variables';

const propTypes = {
    number: PropTypes.string.isRequired,
};

const CircleStyle = styled.div`
    width: 35px;
    height: 35px;
    margin: 0 10px;
    font-size: 0.9em;
    font-family: ${variables.font};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #de1d3c;
    border-radius: 50%;
`;

class Circle extends Component {
    state = {
        activeClass: '',
    }

    setActive = () => {
        const className = 'circle_active';
        for (let i = 0; i<data.length; i++) {
            const obj = data[i];
            if (`${obj.id}` === this.props.number && `${obj.url}` === window.location.href) {
                this.setState({ activeClass: className });
            }
        }
    }

    componentDidMount() {
        this.setActive()
    }

    componentWillUnmount() {
        this.setActive()
    }

    render() {
        const { number } = this.props;
        const { activeClass } = this.state;

        return (
            <CircleStyle className={ activeClass }>
                { number }
            </CircleStyle>
        )
    }
}

Circle.propTypes = propTypes;

export default Circle
