import React, { Fragment } from 'react';
import styled from 'styled-components';
import TextArea from "../Textarea";
import TextAreaSecond from "../TextareaSecond";
import FormWrapper from "../FormWrapper";
import HeadingH1 from "../Heading";
import HtmlLink from "../Link";
import { device, titles } from '../variables';

const FormLabel = styled.label`
    font-size: 0.9em;
    font-weight: 300;
`;

const LinkWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    top: -12px;
    margin: 0 200px;
    
    @media ${device.pad} {
        justify-content: space-between;
        top: 0;
        margin: 0 15px;
    }
    
    @media ${device.mobile} {
        justify-content: space-between;
        top: 0;
        margin: 0 15px;
    }
`;

class PostForm extends React.Component {
    state = {
        minlength: 0,
        disabled: false,
        personal_message: "",
        postcard_sign: ""
    }

    getMinlength = (e) => {
        console.log(this.state.minlength)
        this.setState({
            text: e.target.value,
        })
    }

    render() {
        const { disabled, personal_message, postcard_sign } = this.state;

        return (
            <Fragment>
                <HeadingH1 text={titles.postform} />
                <LinkWrapper>
                    <HtmlLink link="/" text="Назад" />
                    <HtmlLink disabled={disabled} link="/addstickers" text="Далее" />
                </LinkWrapper>
                <FormWrapper>
                    <FormLabel>
                        Личное послание: <sup>*</sup>
                    </FormLabel>
                    <TextAreaSecond
                        value={personal_message}
                        getMinlength={this.getMinlength}
                    />
                    <FormLabel>
                        Подпишите открытку: <sup>*</sup>
                    </FormLabel>
                    <TextArea
                        value={personal_message}
                        getMinlength={this.getMinlength}
                    />
                </FormWrapper>
            </Fragment>
        )
    }
}

export default PostForm;
