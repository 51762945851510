import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Layout from "./components/Layout";
import $ from 'jquery';

const propTypes = {
    children: PropTypes.node.isRequired,
};

function makeid() {
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz";

    for( var i=0; i < 5; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

const imageName = makeid();
const url = 'https://lukoilpostcard.ru/api/';
const imageURL = url.concat(imageName.concat('.png'));

$(function() {
    if(window.location.href === 'https://lukoilpostcard.ru/#/') {
        localStorage.clear();
        localStorage.setItem('imageURL', imageURL);
        localStorage.setItem('imageName', imageName);
    }
    setInterval(function() {
        if(window.location.href === 'https://lukoilpostcard.ru/#/') {
            $('.circleContainer > div').removeClass('circle_active');
            $('.circleContainer > div:nth-child(1)').addClass('circle_active');
        }
        if(window.location.href.indexOf('postform') + 1) {
            $('.circleContainer > div').removeClass('circle_active');
            $('.circleContainer > div:nth-child(2)').addClass('circle_active');
            if ($('.first_ta').val() && $('.second_ta').val()) {
                $('div > a:last-child() > .button_next').removeAttr('disabled');
            } else {
                $('div > a:last-child() > .button_next').attr('disabled', 'disabled');
            }
        } else if(window.location.href.indexOf('sendform') + 1) {
            $('.circleContainer > div').removeClass('circle_active');
            $('.circleContainer > div:nth-child(4)').addClass('circle_active');
            if ($('[name="first_name"]').val() && $('[name="last_name"]').val() && $('[name="send_from"]').val() && $('[name="send_to"]').val()) {
                $('button.send').removeAttr('disabled');
            } else {
                $('button.send').attr('disabled', 'disabled');
            }
        } else if(window.location.href.indexOf('addstickers') + 1) {
            $('#root > main > div.circleContainer > div').removeClass('circle_active');
            $('#root > main > div.circleContainer > div:nth-child(3)').addClass('circle_active');
        } else if(window.location.href.indexOf('sended') + 1) {
            $('#root > main > div.circleContainer > div').removeClass('circle_active');
            $('#root > main > div.circleContainer > div:nth-child(5)').addClass('circle_active');
        }
    }, 100);
});

function App ({ children }) {
    return (
        <Fragment>
            <Layout>
                { children }
            </Layout>
        </Fragment>
    )
}

App.propTypes = propTypes;

export default App;
