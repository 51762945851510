import React, {Fragment} from 'react';
import styled from 'styled-components';
import FormWrapper from "../FormWrapper";
import HeadH1 from "../Heading";
import HtmlLink from "../Link";
import {titles, device} from '../variables';
import axios from "axios";
import { withRouter } from 'react-router';

const FormLabel = styled.label`
    font-size: 0.9em;
    font-weight: 300;
`;

const LinkWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    top: -12px;
    margin: 0 200px;
    
    @media ${device.pad} {
    justify-content: space-between;
    top: 0;
    margin: 0 15px;
    }
    
    @media ${device.mobile} {
    justify-content: space-between;
    top: 0;
    margin: 0 10px;
    }
`;

const Form = styled.form`
    background: white;
    padding: 4rem 5rem;
    
    @media ${device.mobile} {
    padding: 10px;
    }
`;

class SendForm extends React.Component {
    state = {
        first_name: '',
        last_name: '',
        send_from: '',
        send_to: '',
        preview: localStorage.getItem('preview')
    }

    handleSubmit = () => {
        axios({
            method: 'post',
            url: 'https://lukoilpostcard.ru/api/send.php',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: {
                salutation: this.state.salutation,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                send_from: this.state.send_from,
                send_to: this.state.send_to,
                preview: this.state.preview
            }
        }).then(function (response) {
            console.log(response);
        });

        axios({
            method: 'post',
            url: 'https://lukoilpostcard.ru/api/image_decode.php',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: {
                preview: localStorage.getItem('preview'),
                imageName: localStorage.getItem('imageName')
            }
        }).then(function(response) {
            console.log(response);
        });
    }

    selectSalutation (val) {
        this.setState({ salutation: val });
    }

    render() {
        return (
            <Fragment>
                <HeadH1 text={titles.sendform} />
                <LinkWrapper>
                    <HtmlLink link="/addstickers" text="Назад" />
                    <a href="/#/sended" onClick={e => this.handleSubmit( e )}>
                        <button disabled className="button_next send" type="button">Отправить</button>
                    </a>
                </LinkWrapper>
                <FormWrapper>
                    <Form onSubmit={this.handleSubmit}>
                        {/*<select className="select" name="salutation"*/}
                        {/*        value={this.state.salutation}*/}
                        {/*        onChange={e => this.setState({salutation: e.target.value })}>*/}
                        {/*    <option>Господин</option>*/}
                        {/*    <option>Госпожа</option>*/}
                        {/*</select>*/}
                        <FormLabel>
                            Ваше Имя: <sup>*</sup>
                        </FormLabel>
                        <input className="input"
                               type="text"
                               name="first_name"
                               value={this.state.first_name}
                               onChange={e => this.setState({ first_name: e.target.value })}
                        />
                        <FormLabel className="form_label">
                            Ваша Фамилия: <sup>*</sup>
                        </FormLabel>
                        <input
                            className="input"
                            type="text" name="last_name"
                            value={this.state.last_name}
                            onChange={e => this.setState({ last_name: e.target.value })}
                        />
                        <FormLabel className="form_label">
                            Ваш электронный адрес: <sup>*</sup>
                        </FormLabel>
                        <input
                            className="input"
                            type="email"
                            name="send_from"
                            value={this.state.send_from}
                            onChange={e => this.setState({ send_from: e.target.value })}
                        />
                        <FormLabel className="form_label">
                            Электронный адрес получателя: <sup>*</sup>
                        </FormLabel>
                        <input
                            className="input"
                            type="email"
                            name="send_to"
                            value={this.state.send_to}
                            onChange={e => this.setState({ send_to: e.target.value })}
                        />
                        <FormLabel className="form_label">
                            Ваша открытка:
                        </FormLabel>
                        <div className="previewCart">
                            <img className="previewImage" src={localStorage.getItem('preview')} />
                        </div>
                    </Form>
                </FormWrapper>
            </Fragment>
        )
    }
}

export default withRouter(SendForm);
