import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import IndexPage from "./components/pages/Index";
import PostForm from "./components/pages/postform";
import addStickers from "./components/pages/addstickers";
import SendForm from "./components/pages/sendform";
import Send from "./components/pages/send";

ReactDOM.render(
    <Router>
        <App>
            <Switch>
                <Route exact path="/" component={ IndexPage } />
                <Route path="/postform" component={ PostForm } />
                <Route path="/addstickers" component={ addStickers } />
                <Route path="/sendform" component={ SendForm } />
                <Route path="/sended" component={ Send } />
            </Switch>
        </App>
    </Router>,

    document.getElementById('root')
);

