import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MainBlock from "./Wrapper";
import Header from "./Header";
import Circle from "./Circle";
import { device, variables } from './variables';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    children: null,
};

const CircleContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px 0;
    
    @media ${device.pad} {
    padding: 10px 0;
  }

  @media ${device.mobile} {
    padding: 10px 0;
  }
`;

class Layout extends Component {
    state = {
        visibleText: false
    }

    greetingText = () => {
        if(window.location.href === variables.baseUrl + '#/') {
            return (
                <Fragment>
                    <p className="gallery__title">Конструктор открытки от ЛУКОЙЛ</p>
                    <p className="gallery__title">Создайте свою уникальную фирменную открытку ЛУКОЙЛ за несколько простых шагов. Напишите текст, добавьте фирменные картинки в один клик и отправьте своим родным и близким!</p>
                    <p className="gallery__title">Скорее приступайте к созданию письма радости!</p>
                </Fragment>
            )
        }
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({ visibleText: true });
        }, 100);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const { children } = this.props;

        return (
            <MainBlock>
                <Header />
                {this.state.visibleText && this.greetingText()}
                <CircleContainer className="circleContainer">
                    <Circle number={"1"} />
                    <Circle number={"2"} />
                    <Circle number={"3"} />
                    <Circle number={"4"} />
                    <Circle number={"5"} />
                </CircleContainer>
                { children }
            </MainBlock>
        )
    }
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
