const screens = {
    mobile: 320,
    mobileL: 480,
    pad: 768,
    desktop: 1280,
};

export const variables = {
    colors: {
        backgroundColor: '#F2F3F4',
    },
    fontSizes: {
        desktop: '1em',
        pad: '0.9em',
        mobile: '0.8em',
    },
    font: 'FuturaPTBook, sans-serif;',
    baseUrl: 'https://lukoilpostcard.ru/'
};

export const titles = {
    main: `Шаг 1: Выберите конверт`,
    postform: `Шаг 2: Напишите послание`,
    addstickers: `Шаг 3: Создайте уникальный дизайн`,
    sendform: `Шаг 4: Приготовьтесь к отправке`,
    cardsend: `Шаг 5: Ваша открытка отправлена`,
};

export const device = {
    mobile: `(max-width: ${screens.pad - 1}px )`,
    mobileL: `(max-width: ${screens.mobileL}px )`,
    pad: `(min-width: ${screens.pad}px ) and (max-width: ${screens.desktop - 1}px )`,
    desktop: `(min-width: ${screens.desktop}px )`,
};
