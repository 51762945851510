import React,  { Fragment } from 'react';
import styled from 'styled-components';
import { device } from './variables';

const FormTextarea = styled.textarea `
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    min-height: 100px;
    text-align: center;
    font-size: 16px;
    
    @media ${device.mobile} {
    box-sizing: border-box;
    }
`;

const FormLength = styled.span`
    font-size: 0.8rem;
    width: 100%;
    text-align: right;
    display: block;
`;

class TextArea extends React.Component {
    state = {
        maxlength: 60,
        text: localStorage.getItem('message') !== null ? localStorage.getItem('message') : "",
    }

    recalculate = (e) => {
        this.setState({
            text: e.target.value
        });
        localStorage.setItem("message", e.target.value);
    }

    render() {
        const { maxlength } = this.state;
        return (
            <Fragment>
                <FormTextarea
                    className="first_ta"
                    maxLength={maxlength}
                    onInput={this.recalculate}
                    onBlur={this.recalculate}
                    defaultValue={this.state.text}
                />
                <FormLength>
                    {this.state.text.length} / {maxlength}
                </FormLength>
            </Fragment>
        )
    }
}

export default TextArea;
