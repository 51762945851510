import React, {createRef, useState, useCallback, Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Image as KonvaImage, Layer, Stage, Text } from "react-konva";
import useImage from 'use-image';
import { Sticker } from "../Sticker";
import { stickersData } from "../../data/StickersData";
import HeadingH1 from "../Heading";
import HtmlLink from "../Link";
import {variables, device, titles} from '../variables';
import Slider from "react-slick";

const propTypes = {
    img: PropTypes.string,
};

const LinkWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    top: -12px;
    margin: 0 200px;
    
    @media ${device.pad} {
    justify-content: space-between;
    top: 0;
    margin: 0 15px;
    }
    
    @media ${device.mobile} {
    justify-content: space-between;
    top: 0;
    margin: 0 10px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 5px;
    margin: 0 0 10px;
`;

const StickersWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 10px;
    
    @media ${device.pad} {
    flex-wrap: wrap;
    }
    
    @media ${device.mobile} {
    flex-wrap: wrap;
    }
`;

const StickersHeading = styled.h2`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;
    padding: 0;
    font-family: ${variables.font};
    font-weight: 300;
    font-size: ${variables.fontSizes.desktop};
    
    @media ${device.pad} {
    margin-bottom: 10px;
    font-size: ${variables.fontSizes.pad};
    line-height: 30px;
    }

    @media ${device.mobile} {
    font-size: ${variables.fontSizes.mobile};
    margin-bottom: 10px;
    line-height: 24px;
    }
`;

const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
        },
    ]
};

const addStickers = () => {
    const [background] = useImage('./img/counverts/background.jpg');
    const [images, setImages] = useState([]);
    let [stickers, setStickers] = useState('');

    const addStickerToPanel = ({ src, width, x, y }) => {
        setImages((currentImages) => [
            ...currentImages,
            {
                width,
                x,
                y,
                src,
                resetButtonRef: createRef()
            }
        ]);
    };

    const stageRef = React.useRef(null);
    const handleExport = () => {
        const uri = stageRef.current.toDataURL();
        localStorage.setItem('preview', uri);
    };

    const setCanvasWidth = () => {
        if(window.screen.width >= 320 && window.screen.width <= 550) {
            return 300;
        } else if(window.screen.width >= 551 && window.screen.width <= 768) {
            return 400;
        } else {
            return 600;
        }
    }

    const setCanvasHeight = () => {
        if(window.screen.width >= 320 && window.screen.width <= 550) {
            return 300;
        } else if(window.screen.width >= 551 && window.screen.width <= 768) {
            return 400;
        } else {
            return 600;
        }
    }

    const setFontSize = () => {
        if(window.screen.width >= 320 && window.screen.width <= 550) {
            return 15;
        } else if(window.screen.width >= 551 && window.screen.width <= 768) {
            return 23;
        } else {
            return 30;
        }
    }

    const setStickerWidth = () => {
        if(window.screen.width >= 320 && window.screen.width <= 550) {
            return 40;
        } else if(window.screen.width >= 551 && window.screen.width <= 768) {
            return 60;
        } else {
            return 80;
        }
    }

    const resetAllButtons = useCallback(() => {
        images.forEach((image) => {
            if (image.resetButtonRef.current) {
                image.resetButtonRef.current();
            }
        });
    }, [images]);

    const handleCanvasClick = useCallback(
        (event) => {
            if (event.target.attrs.id === "backgroundImage") {
                resetAllButtons();
            }
        },
        [resetAllButtons]
    );

    let arr = [];

    fetch('/stickers.json')
        .then((res) => res.json())
        .then((data) => {
            localStorage.setItem('stickers', data)
        })
        .then(() => {
            setStickers(stickers = localStorage.getItem('stickers'))
        })

    arr = stickers.split(',');

    return (
        <Fragment>
            <HeadingH1 text={titles.addstickers} />
            <LinkWrapper>
                <HtmlLink link="/postform" text="Назад" />
                <HtmlLink link="/sendform" text="Далее" />
            </LinkWrapper>
            <Wrapper>
                <Stage
                    width={setCanvasWidth()}
                    height={setCanvasHeight()}
                    onClick={handleCanvasClick, handleExport}
                    onTap={handleCanvasClick, handleExport}
                    onTouchMove={handleExport}
                    onMouseMove={handleExport}
                    onDragEnd={handleExport}
                    onTouchStart={handleExport}
                    ref={stageRef}
                >
                    <Layer>
                        <KonvaImage
                            image={background}
                            height={setCanvasHeight()}
                            width={setCanvasWidth()}
                            id="backgroundImage"
                        />
                        {images.map((image, i) => {
                            return (
                                <Sticker
                                    onDelete={() => {
                                        const newImages = [...images];
                                        newImages.splice(i, 1);
                                        setImages(newImages);
                                    }}
                                    onDragEnd={(event) => {
                                        image.x = event.target.x();
                                        image.y = event.target.y();
                                    }}
                                    key={i}
                                    image={image}
                                />
                            );
                        })}
                        <Text
                            fontSize={setFontSize()}
                            text={localStorage.getItem('message')}
                            wrap="word"
                            align="center"
                            fontStyle="bold"
                            fontFamily="FuturaPTBook"
                            fill="red"
                            y={Number("20")}
                            draggable
                            padding={Number("5")}
                            width={setCanvasWidth()}
                        />
                        <Text
                            fontSize={setFontSize()}
                            text={localStorage.getItem('name_msg')}
                            wrap="word"
                            align="center"
                            fontStyle="normal"
                            fontFamily="FuturaPTBook"
                            fill="black"
                            y={Number("100")}
                            draggable
                            padding={Number("5")}
                            width={setCanvasWidth()}
                        />
                    </Layer>
                </Stage>
            </Wrapper>
            <StickersHeading>
            <p className="drag_text_notice">Двигайте текст так, как Вам больше нравится!</p>
            </StickersHeading>
            <StickersHeading>
                Выберите стикеры
            </StickersHeading>
            <StickersWrapper>
                <Slider {...settings}>
                    {arr.map((sticker) => {
                        return (
                            <button
                                role="button"
                                className="button sticker_add"
                                onMouseDown={() => {
                                    addStickerToPanel({
                                        src: sticker,
                                        width: setStickerWidth(),
                                        x: 100,
                                        y: 100
                                    });
                                }}
                            >
                                <img alt="" src={sticker} width={setStickerWidth()} />
                            </button>
                        );
                    })}
                </Slider>
            </StickersWrapper>
        </Fragment>
    );
}

addStickers.propTypes = propTypes;

export default addStickers;
