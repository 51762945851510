import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';
import { device } from './variables';

const propTypes = {
    src: PropTypes.string.isRequired,
};

const Img = styled.img`
    width: 350px;
    
    @media ${device.pad} {
        width: 250px;
    }

    @media ${device.mobile} {
        width: 300px;
        margin-bottom: 50px;
    }
`;

class Image extends React.Component {
    state = {
        class: ''
    }

    saveUrl = () => {
        const { src } = this.props;
        localStorage.setItem("src", src);
    }

    render() {
        const { src } = this.props;
        const classes = this.state.class.concat(' convert');

        return (
            <Img className={ classes } onClick={this.saveUrl} src={src} />
        )
    }
}

Image.propTypes = propTypes;

export default Image
